import { fetchOnHoldOrders } from './fetchOnHoldOrders'
import { fetchPaymentStatuses } from './fetchPaymentStatuses'
import { fetchPickingCorrections } from './fetchPickingCorrections'
import { searchInvoices } from './searchInvoices'
import { fetchInvoices } from './fetchInvoices'
import { searchOrders } from './searchOrders'
import { fetchDeliveries } from './fetchDeliveries'
import { OrderActions } from './types'
import { downloadInvoicePdf } from './downloadInvoicePdf'

export const actions: OrderActions = {
  fetchOnHoldOrders,
  fetchPaymentStatuses,
  fetchPickingCorrections,
  searchInvoices,
  fetchInvoices,
  searchOrders,
  fetchDeliveries,
  downloadInvoicePdf
}
