import { InsightsActionNames, InsightsActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_CO2_DATA_QUERY } from '@bidfood/graphql/queries'
import { useUiStore } from '@bidfood/store'

export const fetchCo2Data: InsightsActions[InsightsActionNames.FETCH_CO2_DATA] = async function () {
  const uiStore = useUiStore()
  uiStore.loadingStates.isInsightsLoading = true
    try {
        const { data } = await apolloPortalClient.query({
          query: GET_CO2_DATA_QUERY
        })
        this.carbonFootprint = data.carbonFootprint
        uiStore.loadingStates.isInsightsLoading = false
        return { success: true }
      } catch (e) {
        return { success: false }
      }
}