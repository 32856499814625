import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { uiState } from './state'

const uiStoreId = 'ui'

export const uiStore = defineStore(uiStoreId, {
  state: () => uiState,
  getters,
  actions
})
