import { graphql } from '@bidfood/graphql/generated'

export const CALENDAR_SELECTION_QUERY = graphql(/* GraphQL */ `
    query calendarSelection {
        calendarSelection {
            is_calendar_options_set
            order_reference
            remember_first_possible_delivery_date
            selected_delivery_date
            selected_fulfillment_type
            selected_order_type
            selected_pickup_end_time
            selected_pickup_start_time
            selected_tc
            selected_time_frame
        }
    }
`)
