import { CalendarActionNames, CalendarActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent, FulfillmentType } from '@bidfood/types'
import { UPDATE_CALENDAR_MUTATION } from '@bidfood/graphql/mutations'

export const saveCalendar: CalendarActions[CalendarActionNames.SAVE_CALENDAR] = async function (calendarData) {
  apolloHeaderClient.cache.evict({
    fieldName: 'calendarSelection'
  })
  await apolloHeaderClient.mutate({
    mutation: UPDATE_CALENDAR_MUTATION,
    variables: calendarData,
    fetchPolicy: 'no-cache'
  })

  trigger(CustomEvent.CALENDAR_RESPONSE_SUCCESS, {
    ...calendarData,
    userSelectedDeliveryDate: calendarData.userSelectedDeliveryDate,
    isPickUpSelected: calendarData.fulfillmentType === FulfillmentType.PICK_UP
  })
}
