import { ContactPreferenceActionNames, ContactPreferenceActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { LOCATION_AND_PERSONAL_PREFERENCE_QUERY } from '@bidfood/graphql/queries'

export const fetchLocationAndPersonPreference: ContactPreferenceActions[ContactPreferenceActionNames.FETCH_LOCATION_AND_PERSON_PREFERENCE] =
  async function () {
    const { data } = await apolloPortalClient.query({
      query: LOCATION_AND_PERSONAL_PREFERENCE_QUERY
    })
    this.locationPreferences = data.getUserProfile?.customer?.preference?.locationPreferences || []
    this.personalPreferences = data?.getUserProfile?.customer?.preference?.personalPreferences || []
  }
