import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { purchaseListState } from './state'

const purchaseListStoreId = 'purchaseList'

export const purchaseListStore = defineStore(purchaseListStoreId, {
  state: () => purchaseListState,
  getters,
  actions
})
