import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core'
import { BFF_URI, BFF_URI_LOCAL, errorHandler, isLoginPage } from './config'
import { persistCache, AsyncStorageWrapper } from 'apollo3-cache-persist';
import { BrowserStorageItem } from '@bidfood/types'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.NODE_ENV === 'development' ? BFF_URI_LOCAL.PORTAL : BFF_URI.PORTAL,
  fetchOptions: {
    mode: 'cors'
  }
})
// Cache implementation

const inMemoryCache = new InMemoryCache({ addTypename: false })

const apolloPortalClient = new ApolloClient({
  link: errorHandler.concat(httpLink),
  cache: inMemoryCache
})

export default apolloPortalClient

let apolloPortalPersistentClient : ApolloClient<NormalizedCacheObject> | null = null

export const createApolloPortalClient = async function(key = BrowserStorageItem.PORTAL_GRAPHQL_QUERY_PERSISTENCE_KEY) {
  const persistentMemoryCache = new InMemoryCache({ addTypename: false })

  if(isLoginPage()) {
    return apolloPortalClient
  }

  if (apolloPortalPersistentClient) {
    return  Promise.resolve(apolloPortalPersistentClient)
  }
  const CUSTOMER_AGENT_IDENTIFIER = 'csc'
  const isCustomerAgent = window.location.hostname.includes(CUSTOMER_AGENT_IDENTIFIER)
  await persistCache({
    cache: persistentMemoryCache,
    storage: new AsyncStorageWrapper((isCustomerAgent ? window.sessionStorage: window.localStorage) as any),
    key
  })

  apolloPortalPersistentClient = new ApolloClient({
    link: errorHandler.concat(httpLink),
    cache: persistentMemoryCache,
  })

  return apolloPortalPersistentClient
}
