import { format, parse } from 'date-fns'
import { Invoice, InvoiceOrder } from '@bidfood/graphql/types'
import { formatPriceToEuro } from '../format'

export function sortInvoices (invoices: Invoice[]): Invoice[] {
  return invoices
    .map((invoice: Invoice) => ({
      ...invoice,
      invoiceDate: format(parse(invoice.invoiceDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy'),
      netInvoiceAmountInclTax: invoice.netInvoiceAmountInclTax !== null ? formatPriceToEuro(invoice.netInvoiceAmountInclTax as number): ''
    }))

    .sort((a, b) => {
      const dateA = parse(a.invoiceDate, 'dd-MM-yyyy', new Date())
      const dateB = parse(b.invoiceDate, 'dd-MM-yyyy', new Date())

      return dateB.getTime() - dateA.getTime()
    })
}
export function sortInvoicesOrder (order: InvoiceOrder[]): InvoiceOrder[] {
  return order
    .map((order: InvoiceOrder) => ({
      deliveryDate: format(parse(order.deliveryDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy'),
      orderNumber: `${order.orderNumber || ''}${order.orderNumberATG? `/${order.orderNumberATG}`: ''}`,
      orderNumberATG: order.orderNumberATG || '',
      reference: order.customerReference || '-'
    }))
    .sort((a, b) => {
      const orderNumberATGConverter = (orderNumberATG: string) => Number(orderNumberATG.replace('o', ''))
      return orderNumberATGConverter(b.orderNumberATG) - orderNumberATGConverter(a.orderNumberATG)
    })
}
