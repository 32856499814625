import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { categoryState } from './state'

const catrgoryStoreId = 'category'

export const categoryStore = defineStore(catrgoryStoreId, {
  state: () => categoryState,
  getters,
  actions
})
