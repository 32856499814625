import { OrderActionNames, OrderActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_PAYMENT_STATUSES_QUERY } from '@bidfood/graphql/queries'
import { PaymentStatus } from '@bidfood/graphql/types'

export const fetchPaymentStatuses: OrderActions[OrderActionNames.FETCH_PAYMENT_STATUSES] = async function () {
  const { data } = await apolloPortalClient.query({
    query: GET_PAYMENT_STATUSES_QUERY
  })
  if (data.paymentStatuses) {
    this.paymentStatuses = data.paymentStatuses as PaymentStatus[]
  }
}
