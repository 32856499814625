import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { contactPreferenceState } from './state'

const contactPreferenceStoreId = 'contactPreference'

export const contactPreferenceStore = defineStore(contactPreferenceStoreId, {
  state: () => contactPreferenceState,
  getters,
  actions
})
