import { fetchLocationAndPersonPreference } from './fetchLocationAndPersonPreference'

import { deleteContactPreference } from './deleteContactPreference'

import { updateContactPreference } from './updateContactPreference'

import { addNewContactPreference } from './addNewContactPreference'

import { mutateContactPreference } from './mutateContactPreference'
import { fetchContactPreference } from './fetchContactPreference'
import { ContactPreferenceActions } from './types'

export const actions: ContactPreferenceActions = {
  fetchLocationAndPersonPreference,

  deleteContactPreference,

  updateContactPreference,

  addNewContactPreference,

  mutateContactPreference,
  fetchContactPreference
}
