import { ContactInfo } from '@bidfood/graphql/types'
import { InsightsActionNames, InsightsActions } from './types'
import { createApolloPortalClient } from '@bidfood/clients'
import { SEND_CO2_EMAIL_MUTATION } from '@bidfood/graphql/mutations'

export const toSendCo2Email: InsightsActions[InsightsActionNames.TO_SEND_CO_EMAIL] = async function toSendCo2Email(
    contactInfoDetail: ContactInfo
) {
  const apolloPortalClient = await createApolloPortalClient();
  let isSuccess = false
  try {
      const { data } = await apolloPortalClient.mutate({
        mutation: SEND_CO2_EMAIL_MUTATION,
        variables: contactInfoDetail,
      });
    isSuccess = true
    this.sendCo2Email = data.sendCo2Email;
    } catch (e) {
      isSuccess = false;
    }
    return { isSuccess };
}

