import { UserActionNames, UserActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { useUiStore } from '@bidfood/store'
import { SEARCH_SUGGESTIONS_QUERY } from '@bidfood/graphql/queries'



export const search: UserActions[UserActionNames.SEARCH] = async function (searchQuery) {
  const uiStore = useUiStore()
  uiStore.loadingStates.isSearchLoading = true
  const { data } = await apolloHeaderClient.query({
    query: SEARCH_SUGGESTIONS_QUERY,
    variables: {
      query: searchQuery
    }
  })
  this.searchSuggestions = data.searchSuggestions
  uiStore.loadingStates.isSearchLoading = false
}
