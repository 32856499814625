import { PurchaseListGetterNames, PurchaseListGetters } from './types'
import { MostBoughtPreference } from '@bidfood/graphql/types'

const recentShowPurchaseListMenuOptions: PurchaseListGetters[PurchaseListGetterNames.RECENT_PURCHASE_LIST_MENU_OPTIONS] =
  function ({ customerPurchaseLists }) {
    return customerPurchaseLists?.purchase_lists?.slice(0, 5) || []
  }
const userPurchaseListMenuOptions: PurchaseListGetters[PurchaseListGetterNames.USER_PURCHASE_LIST_MENU_OPTIONS] =
  function ({ userPurchaseLists }) {
    return userPurchaseLists?.purchase_lists || []
  }
const orgPurchaseList: PurchaseListGetters[PurchaseListGetterNames.ORG_PUCHASE_LIST] = function ({
  customerPurchaseLists
}) {
  return customerPurchaseLists?.purchase_lists || []
}
const getIsMostBoughtProductsViewEnabled: PurchaseListGetters[PurchaseListGetterNames.GET_MOST_BOUGHT_PREFERENCE] = ({
  mostBoughtPreference
}) => mostBoughtPreference.includes(MostBoughtPreference.MostBought)

export const getters: PurchaseListGetters = {
  recentShowPurchaseListMenuOptions,
  userPurchaseListMenuOptions,
  orgPurchaseList,
  getIsMostBoughtProductsViewEnabled
}
