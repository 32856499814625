import { graphql } from '@bidfood/graphql/generated'

export const CATEGORY_MENUS_QUERY = graphql(/* GraphQL */ `
query CategoryMenus {
  categoryMenus {
    childCategories {
      id
      link
      name
    }
    id
    link
    name
  }
}
`)