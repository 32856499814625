import { UserActionNames, UserActions } from './types'
import { useUiStore } from '@bidfood/store'
import { apolloPortalClient } from '@bidfood/clients'
import { clearLocalStorage, clearSessionStorage } from '@bidfood/utils'
import { SWITCH_LOCATION_MUTATION } from '@bidfood/graphql/mutations'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent } from '@bidfood/types'

export const changeLocation: UserActions[UserActionNames.CHANGE_LOCATION] = async function changeLocation(
  userSelectedLocation,
  isReload = true
) {
  const uiStore = useUiStore()
  uiStore.loadingStates.isLocationSelectLoading = true
  const { data } = await apolloPortalClient.mutate({
    mutation: SWITCH_LOCATION_MUTATION,
    variables: {
      locationId: userSelectedLocation.id
    },
    fetchPolicy: 'no-cache'
  })
  clearLocalStorage()
  clearSessionStorage()
  uiStore.locationSelectCallback()
  trigger(CustomEvent.LOCATION_SWITCHED)
  if (!isReload) {
    this.selectedLocation = userSelectedLocation
  }
}
