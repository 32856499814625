import { OrderState } from './types'

export const orderState: OrderState = {
  upcoming: [],
  onHold: [],
  history: [],
  searchResults: [],
  invoices: [],
  pickingCorrections: {},
  currentPickingCorrectionSelection: null,
  paymentStatuses: []
}
