import { UserActionNames, UserActions } from './types'
import { createApolloPortalClient } from '@bidfood/clients'
import { USER_PROFILE_QUERY } from '@bidfood/graphql/queries'
import { User } from '@bidfood/graphql/types'

export const fetchUserProfile: UserActions[UserActionNames.FETCH_USER_PROFILE] = async function () {
  const apolloPortalClient = await createApolloPortalClient()
  const { data } = await apolloPortalClient.query({
    query: USER_PROFILE_QUERY
  })
  if (data?.getUserProfile?.customer) {
    this.generalPreferences = data.getUserProfile?.customer?.preference?.generalPreferences ?? []
    this.selectedLocation = {
      id: data.getUserProfile.customer.number,
      name: data.getUserProfile.customer.deliveryAddress?.name || ''
    }
  }

  this.availableLocations =
    data?.getUserProfile?.availableLocations?.map((location) => ({
      id: location?.id || '',
      name: location?.name || ''
    })) || []
  this.user = data.getUserProfile as User

  if (data?.getUserProfile?.navigationMenu) {
    this.navigationMenuOptions = data?.getUserProfile?.navigationMenu
  }
}
