import { OrderActionNames, OrderActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { sortInvoices } from '@bidfood/utils'
import { useUiStore } from '@bidfood/store'
import { GET_INVOICE_QUERY } from '@bidfood/graphql/queries'
import { CustomEvent, CustomerServiceJourneys } from '@bidfood/types'
import { trigger } from '@bidfood/global-event-bus'

export const searchInvoices: OrderActions[OrderActionNames.SEARCH_INVOICES] = async function (searchFor: string) {
  const uiStore = useUiStore()
  uiStore.loadingStates.isInvoiceLoading = true
  try {
    const { data } = await apolloPortalClient.query({
      query: GET_INVOICE_QUERY,
      variables: { searchFor }
    })
    this.invoices = sortInvoices(data.invoices)
    uiStore.loadingStates.isInvoiceLoading = false
  } catch (e) {
    trigger(CustomEvent.SHOW_ERROR_MODAL, {
      customerServiceJourney: CustomerServiceJourneys.INVOICE_TECHNICAL_ERROR
    })
  }
}
