import { graphql } from '@bidfood/graphql/generated'

export const SEARCH_SUGGESTIONS_QUERY = graphql(/* GraphQL */ `
    query searchSuggestions($query: String!) {
    searchSuggestions(query: $query) {
        name
        records {
            name
            url
        }
    }
    }
`)