import { PurchaseListActionNames, PurchaseListActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { DELETE_PURCHASE_LIST_MUTATION } from '@bidfood/graphql/mutations'



export const deleteOrgPurchaseList: PurchaseListActions[PurchaseListActionNames.DELETE_ORG_PURCHASE_LIST] =
  async function deleteOrgPurchaseList(purchaseListId) {
    await apolloHeaderClient.mutate({
      mutation: DELETE_PURCHASE_LIST_MUTATION,
      variables: {
        purchaseListId: purchaseListId
      }
    })
    this.fetchOrgPurchaseList('network-only')
  }
