import { ShopListState } from './types'

export const shopListState: ShopListState = {
  allShopsList: {
    sites: [],
    shops: {
      shops: ''
    }
  }
}
