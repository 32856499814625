import { UiActionNames, UiActions } from './types'

export const openLocationSelect: UiActions[UiActionNames.OPEN_LOCATION_SELECT] = function (callBack, isClosable) {
  this.isLocationSelectOpen = true
  if (isClosable !== undefined) {
    this.isLocationSelectClosable = isClosable
  }
  if (callBack !== undefined) {
    this.locationSelectCallback = callBack
  }
}
