import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { calendarState } from './state'
import { CALENDAR_STORE_ID } from './types'

const calendarStoreId: CALENDAR_STORE_ID = 'calendar'

export const calendarStore = defineStore(calendarStoreId, {
  state: () => calendarState,
  getters,
  actions
})
