import { OrderActionNames, OrderActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_INVOICE_PDF_QUERY } from '@bidfood/graphql/queries'
import { useUiStore } from '@bidfood/store'

export const downloadInvoicePdf: OrderActions[OrderActionNames.DOWNLOAD_INVOICE_PDF] = async function (
  invoiceNumber: string,
  invoiceDate: string
) {
  const uiStore = useUiStore()
  try {
    if (!(invoiceNumber in uiStore.loadingStates.invoiceDownloadLoadingCollection)) {
      uiStore.loadingStates.invoiceDownloadLoadingCollection[invoiceNumber] = false
    }
    uiStore.loadingStates.invoiceDownloadLoadingCollection[invoiceNumber] = true
    const { data } = await apolloPortalClient.query({
      query: GET_INVOICE_PDF_QUERY,
      variables: {
        invoiceNumber,
        invoiceDate
      }
    })
    if (data.downloadInvoice) {
      const fileName = `${invoiceDate.replace(/-/g, '')}_${invoiceNumber.replace(/ /g, '')}_Bidfood_Factuur.pdf`
      const response = await fetch(`data:application/pdf;base64,${data.downloadInvoice}`)
      const blob = await response.blob()

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      URL.revokeObjectURL(link.href)
    }
  } catch (e) {
    uiStore.isDownloadInvoiceErrorModalOpen = true
  } finally {
    uiStore.loadingStates.invoiceDownloadLoadingCollection[invoiceNumber] = false
  }
}
