export enum TrackingEvent {
  PAGE_VIEW = 'pageView',
  CONTACT_DETAILS_UPDATE = 'contact_details_update',
  VIEW_SEARCH_RESULTS = 'view_search_results',
  VIEW_PROMOTION = 'view_promotion',
  SELECT_PROMOTION = 'select_promotion'
}

export type TrackingEventData<T> = T & Record<string, any>;

export interface ContactPreferenceEventData {
  event_step: 'add' | 'modify' | 'delete';
  contact_details: 'email';
  event_status: 'succeed' | 'fail';
  document_type: string;
}
export interface UserProfileEventDate {
  event_step: 'add' | 'modify' | 'delete';
  contact_details_type: string; 
  event_status: 'succeed' | 'fail';
  document_type: null;
}

export interface SearchResultsEventData {
  search_term: string,
  auto_suggestion?: string
}

export interface PromotionEventData {
  ecommerce: {
    items: Array<{
      promotion_id:  string | null,
      promotion_name:  string | null,
      creative_name:  string | null,
      location_id:  string | null,
    }>
  }
}