import { format, parse } from 'date-fns'
import { nl } from 'date-fns/locale'

export function readRegExpArrayFromConfigString (configString: string): Array<RegExp> {
  if (configString.length === 0) {
    return []
  }
  return configString.split(',').map(entry => new RegExp(entry))
}

const FRACTION_DIGITS = 2

export function formatPriceToEuro (price: number, fractionDigits: number = FRACTION_DIGITS) {
  return `€ ${price.toFixed(fractionDigits).replaceAll('.', ',')}`
}

export function formatDate (date: string, dateFormat = 'dd MMMM yyyy') {
  return format(parse(date, 'yyyy-MM-dd', new Date()), dateFormat, { locale: nl }).toLowerCase()
}
const months = [
  { name: 'Jan', title: 'Jan' },
  { name: 'Feb', title: 'Fe' },
  { name: 'Mar', title: 'Mart' },
  { name: 'Apr', title: 'Apr' },
  { name: 'May', title: 'May' },
  { name: 'June', title: 'June' },
  { name: 'July', title: 'July' },
  { name: 'Aug', title: 'Aug' },
  { name: 'Sept', title: 'Sept' },
  { name: 'Oct', title: 'Oct' },
  { name: 'Nov', title: 'Nov' },
  { name: 'Dec', title: 'Dec' }
]
export function getMonthYear (date: string) {
  const d = new Date(date)
  const month = d.getMonth()
  const year = d.getFullYear()
  return `${months[month].title} ${year}`
}


export function capitalize(text: string) {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
