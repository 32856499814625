import { pushEventToDataLayer } from './index'
import { TrackingEvent, SearchResultsEventData } from '@bidfood/types/analytics'

export const triggerSearchResultsEvent = (searchTerm: string, autoSuggestion = null) => {
    if (searchTerm !== '' || autoSuggestion !== null) {
        const viewSearchResultsEventData = {
            searchTerm,
            autoSuggestion,
        }
        pushEventToDataLayer<SearchResultsEventData>(TrackingEvent.VIEW_SEARCH_RESULTS, viewSearchResultsEventData)
    }
}