import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { cartState } from './state'

const cartStoreId = 'cart'

export const cartStore = defineStore(cartStoreId, {
  state: () => cartState,
  getters,
  actions
})
