import { ContactPreferenceActionNames, ContactPreferenceActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent, CustomerServiceJourneys } from '@bidfood/types'
import { useUiStore } from '@bidfood/store'
import { GET_CONTACT_PREFERENCES_QUERY } from '@bidfood/graphql/queries'



export const fetchContactPreference: ContactPreferenceActions[ContactPreferenceActionNames.FETCH_CONTACT_PREFERENCE] =
  async function (document) {
    const uiStore = useUiStore()
    uiStore.loadingStates.isContactPreferenceLoading = true
    try {
      const { data } = await apolloPortalClient.query({
        query: GET_CONTACT_PREFERENCES_QUERY,
        variables: { document }
      })
      this.preferenceBasedOnDocument[document] = data.getContactPreferencesByCustomerNumber
      uiStore.loadingStates.isContactPreferenceLoading = false
    } catch (e) {
      trigger(CustomEvent.SHOW_ERROR_MODAL, {
        customerServiceJourney: CustomerServiceJourneys.CP_TECHNICAL_ERROR
      })
    }
  }
