interface CustomEvent extends Event {
  payload: any
}

function trigger(eventType: string, payload: any = null) {
  //  TODO Handle event bus logging in dev mode
  // if (window.bfDebug) {
  //   console.info('bf-native-event-bus:trigger:', eventType, payload)
  // }
  const event = new CustomEvent(eventType, { detail: payload })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  event.payload = payload
  document.dispatchEvent(event)
}
function on(eventType: string, callback: ($event: CustomEvent) => void, context = null) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.addEventListener(eventType, context ? callback.bind(context) : callback)
}
function off(eventType: string, callback = () => null) {
  document.removeEventListener(eventType, callback)
}

export { trigger, on, off }
