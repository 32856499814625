import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { shopListState } from './state'

const ShopListStoreId = 'ShopList'

export const shopListStore = defineStore(ShopListStoreId, {
  state: () => shopListState,
  getters,
  actions
})
