import { CategoryActionNames, CategoryActions } from './types'
import { createApolloHeaderClient } from '@bidfood/clients'
import { CATEGORY_MENUS_QUERY } from '@bidfood/graphql/queries'


export const fetchCategory: CategoryActions[CategoryActionNames.FETCH_CATEGORY] = async function () {
  const apolloHeaderClient = await createApolloHeaderClient()
  const { data } = await apolloHeaderClient.query({
    query: CATEGORY_MENUS_QUERY
  })
  this.categoryMenus = data.categoryMenus
}
