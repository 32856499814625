import { UiActionNames, UiActions } from './types'
import { SlideOverType } from '@bidfood/types'

export const closeSlideOver: UiActions[UiActionNames.CLOSE_SLIDE_OVER] = function (slideOverType: SlideOverType) {
  this.slideOvers = {
    ...this.slideOvers,
    [slideOverType]: {
      isOpen: false
    }
  }
}
