import { OrderActionNames, OrderActions } from './types'
import { DeliveryExtended, OrderExtended } from '../types'
import { apolloPortalClient } from '@bidfood/clients'
import { Product } from '@bidfood/graphql/types'
import { useUiStore } from '@bidfood/store'
import { GET_PICKING_CORRECTION_PRODUCTS } from '@bidfood/graphql/queries'
import { CustomEvent, CustomerServiceJourneys } from '@bidfood/types'
import { trigger } from '@bidfood/global-event-bus'

export const fetchPickingCorrections: OrderActions[OrderActionNames.FETCH_PICKING_CORRECTIONS] = async function (
  delivery: DeliveryExtended
) {
  const uiStore = useUiStore()
  uiStore.loadingStates.isPickingCorrectionLoading = true
  this.currentPickingCorrectionSelection = `${delivery.deliveryDate}|${delivery.shippingType}`
  const ordersHavingPickingCorrections = delivery.orders.filter(
    (order: OrderExtended) => order.numberOfProductsNotDelivered
  )
  try {
    const pickingCorrectionRequests = ordersHavingPickingCorrections.map((order: OrderExtended) => {
      return apolloPortalClient.query({
        query: GET_PICKING_CORRECTION_PRODUCTS,
        variables: { orderNumber: order.orderNumber, deliveryDate: delivery.deliveryDate }
      })
    })
    const pickingCorrectionResponses = await Promise.all(pickingCorrectionRequests)
    const pickingCorrectionProducts = ordersHavingPickingCorrections
      .map((order: OrderExtended, index) => {
        const pickingCorrectionProductsPerOrder = pickingCorrectionResponses[index].data.products as Product[]
        if (pickingCorrectionProductsPerOrder) {
          const productMap = pickingCorrectionProductsPerOrder.reduce(
            (productMap: Record<string, Product>, product: Product) => {
              if (product?.productId) {
                productMap[product.productId] = product
              }
              return productMap
            },
            {}
          )
          return pickingCorrectionProductsPerOrder
            .filter((product: Product) => !product.pickingCorrectionInformation?.replacementForProduct)
            .map((product: Product) => {
              return {
                orderNumber: order.orderNumber,
                orderNumberATG: order.orderNumberATG,
                orderDate: order.orderDate,
                customerReference: order.customerReference,
                product: product,
                replacementProduct: product.pickingCorrectionInformation?.replacedByProduct
                  ? productMap[product.pickingCorrectionInformation?.replacedByProduct]
                  : null,
                reasonNotDelivered: product.pickingCorrectionInformation?.reasonNotDelivered ?? null
              }
            })
        }
      })
      .flat()
    this.pickingCorrections[this.currentPickingCorrectionSelection] = {
      productOrderDetails: pickingCorrectionProducts,
      totalNumberOfProductsNotDelivered: delivery.orders.reduce(
        (acc, { numberOfProductsNotDelivered }) => acc + (numberOfProductsNotDelivered ?? 0),
        0
      )
    }
    uiStore.loadingStates.isPickingCorrectionLoading = false
  } catch (e) {
    trigger(CustomEvent.SHOW_ERROR_MODAL, {
      customerServiceJourney: CustomerServiceJourneys.ORDERS_TECHNICAL_ERROR
    })
  }
}
