import { graphql } from '@bidfood/graphql/generated'

export const GET_DELIVERIES = graphql(/* GraphQL */ `
    query getDeliveries( $dateFrom: String, $dateTill: String, $searchFor: String, $sortDirection: String) {
        deliveries(dateFrom: $dateFrom, dateTill: $dateTill, searchFor: $searchFor, sortDirection: $sortDirection) {
            deliveryCost
            deliveryDate
            shippingType
            deliveryStatus {
                status {
                    name
                }
                deliveryMoment {
                    from
                    till
                    isDeliveryTimeUpdated
                }
            }
            pickupInformation {
                pickupTimeFrom
                pickupTimeTill
            }
            orders {
                orderDate
                orderNumber
                orderNumberATG
                customerReference
                status {
                    name
                }
                deleted
                numberOfProductsNotDelivered
            }
        }
    }
`)
