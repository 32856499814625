import { graphql } from '@bidfood/graphql/generated'

export const PROMOTION_GROUPS_QUERY = graphql(/* GraphQL */ `
    query PromotionGroups  {
        promotionGroups {
            backEndOffer {
                name,
                url
            }
            promotions {
                name
                url
            }
        }
    }
`)