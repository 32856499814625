import { CategoryGetterNames, CategoryGetters } from './types'
import { CategoryState } from '../types'

const category: CategoryGetters[CategoryGetterNames.CATEGORY] = function (state: CategoryState) {
  return () => state.categoryMenus
}
const childCategory: CategoryGetters[CategoryGetterNames.CHILD_CATEGORY] = function () {
  return (categoryId) => this.category().filter((item) => item.id == categoryId)
}

export const getters: CategoryGetters = {
  category,
  childCategory
}
