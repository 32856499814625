import { OrderActionNames, OrderActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { format, parse, subDays, subMonths } from 'date-fns'
import { Delivery, Order } from '@bidfood/graphql/types'
import { OrderType, ShippingType, CustomEvent, CustomerServiceJourneys } from '@bidfood/types'
import { useUiStore } from '@bidfood/store'
import { GET_DELIVERIES } from '@bidfood/graphql/queries'
import { formatPriceToEuro } from '@bidfood/utils'
import { trigger } from '@bidfood/global-event-bus'

export const searchOrders: OrderActions[OrderActionNames.SEARCH_ORDERS] = async function (searchFor: string) {
  const uiStore = useUiStore()
  uiStore.loadingStates.isDeliveryLoading = true
  this.searchResults = []
  const SEARCH_RESULT_PERIOD_IN_MONTHS = 13
  const dateTill = subDays(new Date(), 1)
  const dateFrom = subMonths(dateTill, SEARCH_RESULT_PERIOD_IN_MONTHS)
  try {
    const { data } = await apolloPortalClient.query({
      query: GET_DELIVERIES,
      variables: {
        searchFor,
        dateFrom: format(dateFrom, 'yyyy-MM-dd')
      }
    })
    this.searchResults = transformSearchResults(data.deliveries)
    uiStore.loadingStates.isDeliveryLoading = false
  } catch (e) {
    trigger(CustomEvent.SHOW_ERROR_MODAL, {
      customerServiceJourney: CustomerServiceJourneys.ORDERS_TECHNICAL_ERROR
    })
  }
}

const deliveriesSortOrder = [
  ShippingType.DELIVER,
  ShippingType.DELIVERED,
  ShippingType.PICK_UP,
  ShippingType.PICKED_UP,
  ShippingType.DELIVER_BY_SUPPLIER,
  ShippingType.DELIVERED_BY_SUPPLIER
]

function transformSearchResults(deliveryDetails: Delivery[]): any[] {
  return deliveryDetails
    .reduce((acc, delivery) => {
      return [
        ...acc,
        ...delivery.orders.map((order: Order) => ({
          shippingType: delivery.shippingType,
          deliveryCost: delivery.deliveryCost ? formatPriceToEuro(delivery.deliveryCost) : '',
          orderDate: order.orderDate ? format(parse(order.orderDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy') : '',
          deliveryDate: delivery.deliveryDate
            ? format(parse(delivery.deliveryDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
            : '',
          orderNumber: `${order.orderNumber || ''}${order.orderNumberATG ? `/${order.orderNumberATG}` : ''}`,
          orderNumberATG: order.orderNumberATG,
          customerReference: order.customerReference || '-',
          status: order?.status?.name || ''
        }))
      ]
    }, [] as any[])
    .sort((a: Delivery, b: Delivery) => {
      const dateA = parse(a.deliveryDate, 'dd-MM-yyyy', new Date())
      const dateB = parse(b.deliveryDate, 'dd-MM-yyyy', new Date())
      const firstLevelSort = dateB.getTime() - dateA.getTime()
      const secondLevelSort =
        deliveriesSortOrder.indexOf(a.shippingType as ShippingType) -
        deliveriesSortOrder.indexOf(b.shippingType as ShippingType)
      return firstLevelSort || secondLevelSort
    })
}
