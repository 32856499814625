import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { analyticsState } from './state'

const analyticsStoreId = 'analytics'

export const analyticsStore = defineStore(analyticsStoreId, {
  state: () => analyticsState,
  getters,
  actions
})
