import { fetchCustomerServiceLocationDetails } from './fetchCustomerServiceLocationDetails'
import { fetchUserLocations } from './fetchUserLocations'
import { fetchUsers } from './fetchUsers'
import { updateUserProfile } from './updateUserProfile'
import { fetchCustomerServiceDetails } from './fetchCustomerServiceDetails'
import { fetchUserProfile } from './fetchUserProfile'
import { UserActions } from './types'
import { changeLocation } from './changeLocation'
import { fetchSearchConfigurations } from './fetchSearchConfigurations'
import { logout } from './logout'
import { search } from './search'
import { fetchLocationsLogin } from './fetchLocationsLogin'

export const actions: UserActions = {
  fetchCustomerServiceLocationDetails,
  fetchUserLocations,
  fetchUsers,
  updateUserProfile,
  fetchCustomerServiceDetails,
  fetchUserProfile,
  changeLocation,
  fetchSearchConfigurations,
  logout,
  search,
  fetchLocationsLogin
}
