import { UserActionNames, UserActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { USERS_QUERY } from '@bidfood/graphql/queries'
import { User } from '@bidfood/graphql/types'
import { useUiStore } from '@bidfood/store'

import { UserExtended } from '../types'
import { capitalize } from '@bidfood/utils'

export const fetchUsers: UserActions[UserActionNames.FETCH_USERS] = async function () {
  const uiStore = useUiStore()
  uiStore.loadingStates.isUserLoading = true
  const { data } = await apolloPortalClient.query({
    query: USERS_QUERY
  })
  this.users = data.users.map((user: User) => {
    const firstName = capitalize(user.firstName)
    const middleName = user.middleName ? user.middleName.toLowerCase() : null
    const lastName = capitalize(user.lastName)
    return {
      ...user,
      fullName: middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`
    }
  }) satisfies UserExtended[]
  this.rolePermissions = data.rolePermissions || []

  uiStore.loadingStates.isUserLoading = false
}
