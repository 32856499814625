import { graphql } from '@bidfood/graphql/generated'

export const GET_ON_HOLD_ORDERS_QUERY = graphql(/* GraphQL */ `
    query getOnHoldOrders {
        onHoldOrders {
            customerReference
            numberOfProductsNotDelivered
            orderDate
            orderNumber
            orderNumberATG
            products{
                brand
                contentInformation{
                    content
                    contentDescription
                    contentUom
                    contentUomDescription
                    salesUom
                    salesUomDescription
                }
                descriptionShort
                imageUrl
                productId
                quantityOrdered
                servingSizeInformation{
                    servingSize
                    servingSizeUom
                    servingSizeUomDescription
                }
                skuId
                specification
                deliveryDateOrderline
            }
        }
    }
`)