import { Banner } from '@bidfood/graphql/types'
import { pushEventToDataLayer } from './index'
import { TrackingEvent, PromotionEventData } from '@bidfood/types/analytics'

function mapBannerToPromotionEventData(banner: Banner): PromotionEventData {
    return{
        ecommerce: {
            items: [
                {
                    promotion_id: banner?.dataId || null,
                    promotion_name: banner?.title || null,
                    creative_name: banner.imageURL || null,
                    location_id: banner?.url || null,
                }
            ]
        }
    }
}
export const triggerViewPromotionEvent = (banner: Banner) => {
    const viewPromotionEventData = mapBannerToPromotionEventData(banner)
    pushEventToDataLayer<PromotionEventData>(TrackingEvent.VIEW_PROMOTION, viewPromotionEventData)
}
export const triggerSelectPromotionEvent = (banner: Banner) => {
    const selectPromotionEventData = mapBannerToPromotionEventData(banner)
    pushEventToDataLayer<PromotionEventData>(TrackingEvent.SELECT_PROMOTION, selectPromotionEventData)
}