import { graphql } from '@bidfood/graphql/generated'

export const UPDATE_CONTACT_PREFERENCE = graphql(/* GraphQL */ `
    mutation createOrUpdateOrDeleteContactPreferencesByCustomerNumber($input: ContactPreferenceInput) {
        createOrUpdateOrDeleteContactPreferencesByCustomerNumber(input: $input) {
            contactPreferences {
                email
            }
        }
    }
`)
