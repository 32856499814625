import { UserActionNames, UserActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_CUSTOMER_SERVICE_DETAILS } from '@bidfood/graphql/queries'

export const fetchCustomerServiceDetails: UserActions[UserActionNames.FETCH_CUSTOMER_SERVICE_DETAILS] =
  async function () {
    try {
      const { data } = await apolloPortalClient.query({
        query: GET_CUSTOMER_SERVICE_DETAILS
      })

      if (data?.currentCustomer?.serviceCenter) {
        this.serviceCenterDetails = data.currentCustomer.serviceCenter
      }
      return { success: true }
    } catch (e) {
      return { success: false }
    }
  }
