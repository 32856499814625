import { graphql } from '@bidfood/graphql/generated'

export const USER_PROFILE_QUERY = graphql(/* GraphQL */ `
  query userProfile {
    getUserProfile {
      firstName
      middleName
      lastName
      email
      secondaryEmail
      phoneNumber
      username
      role
      isPrimaryEmailEnabled
      isPhoneNumberAvailable
      availableLocations {
        id
        name
      }
      customer {
        preference {
            generalPreferences
        }
        deliveryAddress {
            name
        }
        number
      }  
      navigationMenu {
          primary {
              name
              href
              icon
              navigationBehaviour
              preferenceKey
              routeMode
          }
          secondary {
              name
              href
              preferenceKey
              navigationBehaviour
              routeMode
          }
      }
    }
  }
`)