import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { orderState } from './state'

const orderStoreId = 'order'

export const orderStore = defineStore(orderStoreId, {
  state: () => orderState,
  getters,
  actions
})
