import { createPinia, Pinia } from 'pinia'
import { userStore } from './user'
import { UserStore } from './user/types'
import { purchaseListStore } from './purchaseList'
import { PurchaseListStore } from './purchaseList/types'
import { cartStore } from './cart'
import { CartStore } from './cart/types'
import { promotionListStore } from './promotions'
import { PromotionListStore } from './promotions/types'
import { shopListStore } from './shops'
import { ShopListStore } from './shops/types'
import { calendarStore } from './calendar'
import { CalendarStore } from './calendar/types'
import { categoryStore } from './category'
import { CategoryStore } from './category/types'

import { uiStore } from './ui'
import { UiStore } from './ui/types'
import { orderStore } from './order'
import { OrderStore } from './order/types'
import { contactPreferenceStore } from './contact-preference'
import { ContactPreferenceStore } from './contact-preference/types'
import { analyticsStore } from './analytics'
import { AnalyticsStore } from './analytics/types'
import { insightsStore } from './insights'
import { InsightsStore } from './insights/types'

export const pinia = (): Pinia => {
  if (window.piniaInstance !== undefined) return window.piniaInstance

  window.piniaInstance = createPinia()
  return window.piniaInstance
}

export const useUserStore: () => UserStore = () => userStore(pinia())
export const usePurchaseListStore: () => PurchaseListStore = () => purchaseListStore(pinia())
export const useCartStore: () => CartStore = () => cartStore(pinia())
export const usePromotionsStore: () => PromotionListStore = () => promotionListStore(pinia())
export const useUiStore: () => UiStore = () => uiStore(pinia())
export const useShopStore: () => ShopListStore = () => shopListStore(pinia())
export const useOrderStore: () => OrderStore = () => orderStore(pinia())
export const useContactPreferenceStore: () => ContactPreferenceStore = () => contactPreferenceStore(pinia())
export const useCalendarStore: () => CalendarStore = () => calendarStore(pinia())
export const useCategoryStore: () => CategoryStore = () => categoryStore(pinia())
export const useAnalyticsStore: () => AnalyticsStore = () => analyticsStore(pinia())
export const useInsightsStore: () => InsightsStore = () => insightsStore(pinia())
