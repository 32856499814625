import { pushEventToDataLayer } from './index'
import { TrackingEvent, ContactPreferenceEventData } from '@bidfood/types/analytics'

export const triggerContactPreferenceEvent = (input: { document: string; emailNew: string; emailOld?: string }, isSuccess: boolean) => {
  if (input.emailNew != input.emailOld) {
    const contactPreferenceEventData = {
      event_step: input.emailNew && !input.emailOld ? 'add' : !input.emailNew && input.emailOld ? 'delete' : 'modify',
      contact_details_type: 'email',
      event_status: isSuccess ? 'succeed' : 'fail',
      document_type: input.document
    }
    pushEventToDataLayer<ContactPreferenceEventData>(TrackingEvent.CONTACT_DETAILS_UPDATE, contactPreferenceEventData)
  }

}
