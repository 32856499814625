import { UserGetterNames, UserGetters } from './types'
import { UserState } from '../types'
import { GeneralPreferences } from '@bidfood/graphql/types'

const userFullName: UserGetters[UserGetterNames.USER_FULL_NAME] = function (state: UserState) {
  return () => `${state.user.firstName} ${state.user.middleName || ''} ${state.user.lastName}`
}

const canShowAllPurchaseList: UserGetters[UserGetterNames.CAN_SHOW_ALL_PURCHASE_LIST] = function ({
  generalPreferences
}) {
  return generalPreferences.includes(GeneralPreferences.AllePurchaselist)
}
const canShowNewPurchaseList: UserGetters[UserGetterNames.CAN_SHOW_NEW_PURCHASE_LIST] = function ({
  generalPreferences
}) {
  return generalPreferences.includes(GeneralPreferences.NewPurchaselist)
}
const canShowSearchInPurchaseList: UserGetters[UserGetterNames.CAN_SHOW_SEARCH_IN_PURCHASE_LIST] = function ({
  generalPreferences
}) {
  return generalPreferences.includes(GeneralPreferences.SearchInPurchaselist)
}
const canShowPreviouslyBought: UserGetters[UserGetterNames.CAN_SHOW_PREVIOUSLY_BOUGHT] = function ({
  generalPreferences
}) {
  return generalPreferences.includes(GeneralPreferences.PreviouslyBought)
}

const canShowPromotions: UserGetters[UserGetterNames.CAN_SHOW_PROMOTIONS] = function ({ generalPreferences }) {
  return generalPreferences.includes(GeneralPreferences.Ambidigen)
}

const canDownloadInvoicePdf: UserGetters[UserGetterNames.CAN_DOWNLOAD_INVOICE_PDF] = function ({ generalPreferences }) {
  return generalPreferences.includes(GeneralPreferences.InvoiceDownloadAndPaymentStatus)
}

export const getters: UserGetters = {
  userFullName,
  canShowAllPurchaseList,
  canShowNewPurchaseList,
  canShowSearchInPurchaseList,
  canShowPreviouslyBought,
  canShowPromotions,
  canDownloadInvoicePdf
}
