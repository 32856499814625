import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { promotionListState } from './state'

const promotionListStoreId = 'promotionList'

export const promotionListStore = defineStore(promotionListStoreId, {
  state: () => promotionListState,
  getters,
  actions
})
