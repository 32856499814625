import { graphql } from '@bidfood/graphql/generated'

export const LOCATION_AND_PERSONAL_PREFERENCE_QUERY = graphql(/* GraphQL */ `
    query locationAndPersonalPreference {
        getUserProfile {
            customer {
                preference {
                    locationPreferences,
                    personalPreferences
                }
            }
        }
    }
`)
