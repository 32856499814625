import { UserState } from './types'
import { ChatStatus } from '@bidfood/graphql/types'

export const userState: UserState = {
  navigationMenuOptions: {
    primary: [],
    secondary: []
  },
  availableLocations: [],
  selectedLocation: {
    name: '',
    id: ''
  },
  generalPreferences: [],
  searchSuggestions: [],
  searchBoxConfiguration: null,
  user: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    username: '',
    role: null
  },
  serviceCenterDetails: {
    showChat: true,
    email: '',
    phoneNumber: '',
    city: '',
    liveChatChannel: ''
  },
  users: [],
  rolePermissions: [],
  locations: [],
  customerDetails: {}
}
