import { pushEventToDataLayer } from './index'
import { TrackingEvent, UserProfileEventDate } from '@bidfood/types/analytics'
import { UserProfileInput } from '@bidfood/graphql/types'

export const triggerPersonalDetailsEvent = (userProfileInput: UserProfileInput, isSuccess: boolean) => {
  const contactDetailTypeMap : Record<string, string> = {
    firstName: 'first_name',
    middleName: 'middle_name',
    lastName: 'last_name',
    email: 'primary_email',
    secondaryEmail: 'extra_email',
    phoneNumber: 'mobile_number'
  }

  for (const key in userProfileInput.userProfileInputNew) {
    if ( key in contactDetailTypeMap ) { 
      const newValue = userProfileInput.userProfileInputNew[key]
      const oldValue = userProfileInput.userProfileInputOld[key]
      if (newValue != oldValue) {
        const eventStep = newValue && !oldValue ? 'add' : (!newValue && oldValue ? 'delete' : 'modify')
        const userProfileEventDate = {
          event_step: eventStep,
          contact_details_type: contactDetailTypeMap[key],
          event_status: isSuccess ? 'succeed' : 'fail',
          document_type: null
        }
        pushEventToDataLayer<UserProfileEventDate>(TrackingEvent.CONTACT_DETAILS_UPDATE, userProfileEventDate)
      }
    }
  }
}