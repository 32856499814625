import { UserActionNames, UserActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { clearLocalStorage, clearSessionStorage } from '@bidfood/utils'
import { LOGOUT_MUTATION } from '@bidfood/graphql/mutations'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent } from '@bidfood/types'

export const logout: UserActions[UserActionNames.LOGOUT] = async function () {
  const { data } = await apolloHeaderClient.mutate({
    mutation: LOGOUT_MUTATION,
    fetchPolicy: 'no-cache'
  })
  clearLocalStorage()
  clearSessionStorage()
  trigger(CustomEvent.USER_LOGOUT);
  window.location.href = '/webshop'
}
