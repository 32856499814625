import { ShopListActionNames, ShopListActions } from './types'
import { createApolloHeaderClient } from '@bidfood/clients'
import { SHOPS_QUERY } from '@bidfood/graphql/queries'

export const fetchShopList: ShopListActions[ShopListActionNames.FETCH_Shop_LIST] = async function () {
  const apolloHeaderClient = await createApolloHeaderClient()
  const { data } = await apolloHeaderClient.query({
    query: SHOPS_QUERY
  })
  this.allShopsList = data
}
