import { graphql } from '@bidfood/graphql/generated'

export const PURCHASE_LIST_QUERY = graphql(/* GraphQL */ `
    query purchaseList {
        customerPurchaseLists {
            purchase_list_count
            purchase_lists {
                purchaseListName,
                purchaseListId,
                isShared,
                isEditableByCurrentUser
            }
        }
        userPurchaseLists {
            purchase_lists {
                purchaseListName
                purchaseListId
                isShared,
                isEditableByCurrentUser
            }
        }
        mostBoughtPreference
    }
`)
