import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { insightsState } from './state'

const insightsStoreId = 'insights'

export const insightsStore = defineStore(insightsStoreId, {
  state: () => insightsState,
  getters,
  actions
})