import { CalendarActions } from './types'
import { fetchCalendar } from './fetchCalendar'
import { saveCalendar } from './saveCalendar'
import { fetchSavedCalendar } from './fetchSavedCalendar'

export const actions: CalendarActions = {
  fetchCalendar,
  saveCalendar,
  fetchSavedCalendar
}
