import { graphql } from '@bidfood/graphql/generated'

export const USERS_QUERY = graphql(/* GraphQL */ `
    query users {
        users {
            id
            firstName
            middleName
            lastName
            email
            phoneNumber
            role
            availableLocations {
                id
                name
            }
            isActive
        }
        rolePermissions {
            role
            permissions
        }
    }
`)
