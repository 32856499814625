import { UserActionNames, UserActions } from './types'
import { createApolloPortalClient } from '@bidfood/clients'
import { User, UserProfileInput } from '@bidfood/graphql/types'
import { UPDATE_USER_PROFILE_BY_USER_ID } from '@bidfood/graphql/mutations'
import { triggerPersonalDetailsEvent } from '@bidfood/utils/analytics'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent, CustomerServiceJourneys } from '@bidfood/types'

export const updateUserProfile: UserActions[UserActionNames.UPDATE_USER_PROFILE] = async function updateUserProfile(
  userProfileInput: UserProfileInput,
  enableModalError: boolean = true
) {
  const apolloPortalClient = await createApolloPortalClient()
  let isSuccess = false
  try {
    const { data } = await apolloPortalClient.mutate({
      mutation: UPDATE_USER_PROFILE_BY_USER_ID,
      variables: {
        userProfileInput
      },
      fetchPolicy: 'no-cache',
      update: (cache) => {
        cache.evict({
          fieldName: 'getUserProfile'
        })
      }
    })
    this.user = data?.updateUserProfileByUserId as User
    isSuccess = true
  } catch (e) {
    isSuccess = false
    if (enableModalError) {
      trigger(CustomEvent.SHOW_ERROR_MODAL, {
        customerServiceJourney: CustomerServiceJourneys.PERSONAL_DETAILS_TECHNICAL_ERROR
      })
    } 
  } finally {
    triggerPersonalDetailsEvent(userProfileInput, isSuccess)
  }
  return { isSuccess }
}
