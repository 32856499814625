import { UserActionNames, UserActions } from './types'
import { createApolloHeaderClient } from '@bidfood/clients'
import { SEARCH_BOX_CONFIGURATION_QUERY } from '@bidfood/graphql/queries'



export const fetchSearchConfigurations: UserActions[UserActionNames.FETCH_SEARCH_CONFIGURATIONS] = async function () {
  const apolloHeaderClient = await createApolloHeaderClient()
  const { data } = await apolloHeaderClient.query({
    query: SEARCH_BOX_CONFIGURATION_QUERY
  })
  this.searchBoxConfiguration = data.searchBoxConfiguration
}
