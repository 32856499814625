import { graphql } from '@bidfood/graphql/generated'

export const GET_CUSTOMER_LOCATION_DETAILS_QUERY = graphql(/* GraphQL */ `
    query getCustomerLocationDetails ($customerNumber: String!) {
  customer(customerNumber: $customerNumber) {
    name
    number
    deliveryTimesInWeek {
      dayOfWeek
      deliveryTime1 {
        from
        till
      }
      deliveryTime2 {
        from
        till
      }
    }
    deliveryAddress {
      city
      country
      houseNumber
      name
      houseNumberAddition
      postalCode
      street
    }
  }
}
`)
