import { OrderActionNames, OrderActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_ON_HOLD_ORDERS_QUERY } from '@bidfood/graphql/queries'
import { OrderExtended } from '../types'
import { format, parse } from 'date-fns'
import { useUiStore } from '@bidfood/store'
import { Order } from '@bidfood/graphql/types'
import { trigger } from '@bidfood/global-event-bus'
import { CustomEvent, CustomerServiceJourneys } from '@bidfood/types'

export const fetchOnHoldOrders: OrderActions[OrderActionNames.FETCH_ON_HOLD_ORDERS] = async function () {
  const uiStore = useUiStore()
  uiStore.loadingStates.isOnHoldOrdersLoading = true
  try {
    const { data } = await apolloPortalClient.query({
      query: GET_ON_HOLD_ORDERS_QUERY
    })
    this.onHold = data.onHoldOrders.map((onHoldOrders: Order) => ({
      ...onHoldOrders,
      orderDate: format(parse(onHoldOrders.orderDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')
    })) as OrderExtended[]
    uiStore.loadingStates.isOnHoldOrdersLoading = false
  } catch (e) {
    trigger(CustomEvent.SHOW_ERROR_MODAL, {
      customerServiceJourney: CustomerServiceJourneys.ORDERS_TECHNICAL_ERROR
    })
  }
}
