import { closeSlideOver } from './closeSlideOver'
import { openSlideOver } from './openSlideOver'
import { closeInvoiceDownloadErrorModal } from './closeInvoiceDownloadErrorModal'
import { toggleLocationSelect } from './toggleLocationSelect'
import { toggleCustomerServiceModal } from './toggleCustomerServiceModal'
import { openLocationSelect } from './openLocationSelect'
import { closeLocationSelect } from './closeLocationSelect'
import { openCalendarModal } from './openCalendarModal'
import { closeCalendarModal } from './closeCalendarModal'
import { openCategorySlideOver } from './openCategorySlideOver'
import { closeCategorySlideOver } from './closeCategorySlideOver'
import { openOffersSlideOver } from './openOffersSlideOver'
import { closeOffersSlideOver } from './closeOffersSlideOver'
import { openShopSlideOver } from './openShopSlideOver'
import { closeShopSlideOver } from './closeShopSlideOver'
import { UiActions } from './types'

export const actions: UiActions = {
  closeSlideOver,
  openSlideOver,
  closeInvoiceDownloadErrorModal,
  toggleLocationSelect,
  toggleCustomerServiceModal,
  openLocationSelect,
  closeLocationSelect,
  openCalendarModal,
  closeCalendarModal,
  openCategorySlideOver,
  closeCategorySlideOver,
  openOffersSlideOver,
  closeOffersSlideOver,
  openShopSlideOver,
  closeShopSlideOver
}
