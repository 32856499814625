import { UiActionNames, UiActions } from './types'
import { SlideOverType } from '@bidfood/types'

export const openSlideOver: UiActions[UiActionNames.OPEN_SLIDE_OVER] = function (
  slideOverType: SlideOverType,
  options = { isOpen: true, canClose: true}
) {
  this.slideOvers = {
    ...this.slideOvers,
    [slideOverType]: options
  }
}
