import { LocalStorageItem, BrowserStorageItem } from '@bidfood/types'

export function clearLocalStorage() {
  localStorage.removeItem(LocalStorageItem.CART_INFO_KEY)
  localStorage.removeItem(LocalStorageItem.LS_ADD_TO_CART_LOCATION)
  localStorage.removeItem(BrowserStorageItem.HEADER_GRAPHQL_QUERY_PERSISTENCE_KEY)
  localStorage.removeItem(BrowserStorageItem.PORTAL_GRAPHQL_QUERY_PERSISTENCE_KEY)
}

export function clearSessionStorage() {
  sessionStorage.removeItem(BrowserStorageItem.HEADER_GRAPHQL_QUERY_PERSISTENCE_KEY)
  sessionStorage.removeItem(BrowserStorageItem.PORTAL_GRAPHQL_QUERY_PERSISTENCE_KEY)
}

