export enum PageUrl {
  LOGIN_URL = '/webshop/myaccount/loginRegistration.jsp',
  OCI_LOGIN_URL = '/webshop/oci/ociLogin.jsp',
  REGISTER_URL = '/webshop/klant-worden/register.jsp',
  WEBSHOP = '/webshop',
  PURCHASE_LIST = '/webshop/purchaseList/viewPurchaseListDispatcher.jsp',
  PURCHASE_LIST_SEARCH = '/webshop/search/purchaseListProductSearchForm.jsp',
  MOST_BOUGHT = '/webshop/purchase/mostBought.jsp',
  VALIDATE_FORGOT_PASSWORD = '/webshop/myaccount/validateForgottenPassword.jsp',
  NEW_PRODUCT = '/webshop/assortiment/_/N-1rc100h'
}
