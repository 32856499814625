import { TrackingEvent, TrackingEventData } from '@bidfood/types/src/analytics'
export { triggerContactPreferenceEvent } from './triggerContactPreferenceEvent'
export { triggerPersonalDetailsEvent } from './triggerPersonalDetailsEvent'
export { triggerSearchResultsEvent } from './triggerSearchResultsEvent'
export { triggerSelectPromotionEvent, triggerViewPromotionEvent } from './triggerPromotionEvent'

export const pushEventToDataLayer = <T>(trackingEvent: TrackingEvent, eventData?: TrackingEventData<T>): void => {
  const IS_ANALYTICS_ENABLED = true; // NOTE: Replace with a check for the analytics cookie if needed
  if (IS_ANALYTICS_ENABLED) {
    const event = { event: trackingEvent, ...eventData };
    window.dataLayer = window.dataLayer || [];
    if (process.env.NODE_ENV === 'development') {
      console.log(event)
    }
    window.dataLayer.push(event);
  }
};
