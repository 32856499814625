import { graphql } from '@bidfood/graphql/generated'

export const GET_PICKING_CORRECTION_PRODUCTS = graphql(/* GraphQL */ `
    query getPickingCorrectionProducts($orderNumber: String!, $deliveryDate: String!) {
        products(orderNumber: $orderNumber, deliveryDate: $deliveryDate, pickingCorrection: true) {
            productId
            brand
            descriptionShort
            contentInformation {
                salesUomDescription
                content
                contentUom
                contentUomDescription
                contentDescription
                salesUom
            }
            quantityOrdered
            quantityDelivered
            servingSizeInformation {
                servingSizeUom
                servingSizeUomDescription
                servingSize
            }
            specification
            pickingCorrectionInformation {
                reasonNotDelivered
                replacedByProduct
                replacementForProduct
            }
            imageUrl
        }
    }
`)
