import { endOfDay, endOfWeek, startOfDay, startOfWeek, subDays, subWeeks, lastDayOfWeek } from 'date-fns'
import { QuickFilter } from '@bidfood/types'

export const calculateQuickFilterDateRange = (filter: QuickFilter, { canSkipToday } : {canSkipToday: boolean}): { start: Date; end: Date } => {
  const today = new Date()
  const actualDate = canSkipToday ? subDays(today, 1) : today
  switch (filter) {
    case QuickFilter.THIS_WEEK:
      return {
        start: startOfWeek(today),
        end: canSkipToday ? endOfDay(actualDate) : endOfDay(lastDayOfWeek(today))
      }
    case QuickFilter.LAST_WEEK: {
      const SUBTRACT_WEEK_BY = 1
      const lastWeek = subWeeks(today, SUBTRACT_WEEK_BY)
      return { start: startOfWeek(lastWeek), end: endOfDay(endOfWeek(lastWeek)) }
    }
    case QuickFilter.LAST_30_DAYS: {
      const SUBTRACT_DAY_BY = 30
      return { start: startOfDay(subDays(actualDate, SUBTRACT_DAY_BY)), end: endOfDay(actualDate) }
    }
  }
}
