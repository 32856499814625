import { PurchaseListActionNames, PurchaseListActions } from './types'
import { PURCHASE_LIST_QUERY } from '@bidfood/graphql/queries'
import { FetchPolicy } from '@apollo/client'
import { createApolloHeaderClient } from '@bidfood/clients'

export const fetchOrgPurchaseList: PurchaseListActions[PurchaseListActionNames.FETCH_ORG_PURCHASE_LIST] =
  async function (fetchPolicy: FetchPolicy = 'cache-first') {
    const apolloHeaderClient = await createApolloHeaderClient()
    const { data } = await apolloHeaderClient.query({
      query: PURCHASE_LIST_QUERY,
      fetchPolicy
    })
    this.customerPurchaseLists = data.customerPurchaseLists || []
    this.userPurchaseLists = data.userPurchaseLists || []
    this.mostBoughtPreference = data.mostBoughtPreference || []
  }
