import { PurchaseListActionNames, PurchaseListActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { CREATE_PURCHASE_LIST_MUTATION } from '@bidfood/graphql/mutations'
import { CreatePurchaseListInput } from '@bidfood/graphql/types'

export const createOrgPurchaseList: PurchaseListActions[PurchaseListActionNames.CREATE_ORG_PUCHASE_LIST] =
  async function createOrgPurchaseList(createPurchaseListInput: CreatePurchaseListInput) {
    const { data } = await apolloHeaderClient.mutate({
      mutation: CREATE_PURCHASE_LIST_MUTATION,
      variables: {
        createPurchaseListInput
      }
    })
    this.fetchOrgPurchaseList('network-only')
  }
