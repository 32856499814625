import { PromotionListState } from './types'

export const promotionListState: PromotionListState = {
  promotionGroups: {
    backEndOffer: {
      name: '',
      url: ''
    },
    promotions: []
  }
}
