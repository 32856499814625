import { CalendarActionNames, CalendarActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { CALENDAR_QUERY } from '@bidfood/graphql/queries'
import { CalendarSelection as CalendarSelectionOld, DeliveryDateInfo, Maybe } from '@bidfood/graphql/types'
import { CalendarSelection } from '../types'

export const fetchCalendar: CalendarActions[CalendarActionNames.FETCH_CALENDAR] = async function fetchCalendar(
  year,
  month
) {
  const { data } = await apolloHeaderClient.query({
    query: CALENDAR_QUERY,
    variables: {
      year: parseInt(year),
      month: parseInt(month)
    }
  })
  if (data.calendar) {
    this.calendar = data.calendar
  }
  if (data.calendarSelection) {
    this.calendarSelection = calendarSelectionMapper(data.calendarSelection)
  }
  this.calenderPreferences = data?.calenderPreferences || []
}

//  TODO - Remove this after changes in BFF  on 3rd March 2023
const calendarSelectionMapper = (calendarSelectionOld: CalendarSelectionOld) =>
  ({
    isCalendarOptionsSet: calendarSelectionOld.is_calendar_options_set as Maybe<boolean>,
    orderReference: calendarSelectionOld.order_reference as Maybe<string>,
    rememberFirstPossibleDeliveryDate: calendarSelectionOld.remember_first_possible_delivery_date as Maybe<boolean>,
    selectedDeliveryDate: calendarSelectionOld.selected_delivery_date as Maybe<string>,
    selectedFulfillmentType: calendarSelectionOld.selected_fulfillment_type as Maybe<string>,
    selectedOrderType: calendarSelectionOld.selected_order_type as Maybe<string>,
    selectedPickupEndTime: calendarSelectionOld.selected_pickup_end_time as Maybe<string>,
    selectedPickupStartTime: calendarSelectionOld.selected_pickup_start_time as Maybe<string>,
    selectedTc: calendarSelectionOld.selected_tc as Maybe<string>,
    selectedTimeFrame: calendarSelectionOld.selected_time_frame as Maybe<string>
  } satisfies CalendarSelection)
