import { graphql } from '@bidfood/graphql/generated'

export const SHOPS_QUERY = graphql(/* GraphQL */ `
    query Shops {
        shops {
            shops
            sites {
                name
                url
            }   
        }
    }
`)