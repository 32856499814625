import { format, parse } from 'date-fns'
import { Order } from '@bidfood/graphql/types'

export const processOrders = (orders: Order[], canShowBadge = false) => {
  return orders
    .map((order: Order) => ({
      orderDate: format(parse(order.orderDate, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy'),
      orderNumberFormatted: `${order.orderNumber || ''}${order.orderNumberATG? `/${order.orderNumberATG}`: ''}`,
      orderNumber: order.orderNumber || '',
      orderNumberATG: order.orderNumberATG || '',
      customerReference: order.customerReference || '-',
      status: order?.status?.name || '',
      ...(canShowBadge && { numberOfProductsNotDelivered: order.numberOfProductsNotDelivered })
    }))
}
