import { CartActionNames, CartActions } from './types'
import { on } from '@bidfood/global-event-bus'
import {BrowserStorageItem, CustomEvent} from "@bidfood/types";

export const syncMiniCart: CartActions[CartActionNames.SYNC_MINI_CART] = function () {
  const updateCartCount = ({ payload }) => {
    const { store, state } = payload
    if (store === 'cart' && state?.getCartSummary) {
      this.totalCartCount = state.getCartSummary.totalCount || 0;
    }
  };
  const webShopPersistedState = JSON.parse(localStorage.getItem(BrowserStorageItem.WEB_SHOP_PERSISTED_STATE_KEY));
  if (webShopPersistedState?.cart?.getCartSummary) {
    this.totalCartCount = webShopPersistedState.cart.getCartSummary.totalCount || 0;
  }
  on(CustomEvent.WEB_SHOP_STATE_PERSISTED, updateCartCount);
  on(CustomEvent.WEB_SHOP_STATE_RESTORED, updateCartCount);
}
