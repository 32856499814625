import { PromotionListGetterNames, PromotionListGetters } from './types'
import { PromotionListState } from '../types'

const promotionList: PromotionListGetters[PromotionListGetterNames.PROMOTION_LIST] = function (
  state: PromotionListState
) {
  return () => ({
    promotions: state.promotionGroups.promotions,
    backEndOffer: state.promotionGroups.backEndOffer
  })
}
const backEndOfferData: PromotionListGetters[PromotionListGetterNames.BACK_END_OFFER_DATA] = function () {
  return () => this.promotionList().backEndOffer || {}
}
export const getters: PromotionListGetters = {
  promotionList,
  backEndOfferData
}
