import { PurchaseListState } from './types'

export const purchaseListState: PurchaseListState = {
  customerPurchaseLists: {
    purchase_lists: []
  },
  userPurchaseLists: {
    purchase_lists: []
  },
  mostBoughtPreference: []
}
