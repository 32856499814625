import { graphql } from '@bidfood/graphql/generated'

export const CALENDAR_QUERY = graphql(/* GraphQL */ `
    query calendar($year: Int!, $month: Int!) {
        calendar(year: $year, month: $month) {
            deliveryDates {
                deliveryDate
                isRegularDeliveryDay
                hasPlannedDeliveryOrders
                hasPlannedOrdersToDeliverByExternalParty
                hasPlannedPickupOrders
                isAlternativeDeliveryDay
                isDryFoodsDeliveryPossible
                isFreshGoodsDeliveryPossible
                isFrozenGoodsDeliveryPossible
                isIncidentalDeliveryDay
                isSiteClosedForDelivery
            }
            pickupTcs {
                city
                tcId
                timeFrames {
                    afternoonEndTime
                    afternoonStartTime
                    day
                    morningEndTime
                    morningStartTime
                    open
                }
            }
            deliveryInfoMessage
        }
        calendarSelection {
            is_calendar_options_set
            order_reference
            remember_first_possible_delivery_date
            selected_delivery_date
            selected_fulfillment_type
            selected_order_type
            selected_pickup_end_time
            selected_pickup_start_time
            selected_tc
            selected_time_frame
        }
        calenderPreferences
    }
`)
