import { graphql } from '@bidfood/graphql/generated'

export const GET_INVOICE_QUERY = graphql(/* GraphQL */ `
query invoices($dateFrom: String, $dateTill: String, $searchFor: String) {
  invoices(dateFrom: $dateFrom, dateTill: $dateTill, searchFor: $searchFor) {
    invoiceNumber
    invoiceDate
    netInvoiceAmountInclTax
    paymentStatus{
        code
        description
        color
    }
    orders {
      orderNumberATG
      orderNumber
      deliveryDate
      customerReference
    }
    }
}
`)