import { PromotionListActionNames, PromotionListActions } from './types'
import { createApolloHeaderClient } from '@bidfood/clients'
import { PROMOTION_GROUPS_QUERY } from '@bidfood/graphql/queries'



export const fetchPromotionList: PromotionListActions[PromotionListActionNames.FETCH_PROMOTION_LIST] =
  async function () {
    const apolloHeaderClient = await createApolloHeaderClient()
    const { data } = await apolloHeaderClient.query({
      query: PROMOTION_GROUPS_QUERY
    })
    this.promotionGroups = data.promotionGroups
  }
