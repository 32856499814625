import { PurchaseListActions } from './types'
import { fetchOrgPurchaseList } from './fetchOrgPurchaseList'
import { createOrgPurchaseList } from './createOrgPurchaseList'
import { deleteOrgPurchaseList } from './deleteOrgPurchaseList'
import { deletePurchaseListCache } from './deletePurchaseListCache'

export const actions: PurchaseListActions = {
  createOrgPurchaseList,
  deleteOrgPurchaseList,
  fetchOrgPurchaseList,
  deletePurchaseListCache
}
