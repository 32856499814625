import { ShopListGetterNames, ShopListGetters } from './types'
import { ShopListState } from '../types'

const allShopList: ShopListGetters[ShopListGetterNames.ALL_Shop_LIST] = function (state: ShopListState) {
  return () => ({
    shops: state.allShopsList.shops,
    sites: state.allShopsList.sites
  })
}
const shops: ShopListGetters[ShopListGetterNames.SHOPS] = function ({ allShopsList }) {
  return allShopsList.shops.shops.replaceAll('<i class="icon-minus"></i>', '') || ''
}
export const getters: ShopListGetters = {
  allShopList,
  shops
}
