import { CalendarActionNames, CalendarActions } from './types'
import { apolloHeaderClient } from '@bidfood/clients'
import { CALENDAR_SELECTION_QUERY } from '@bidfood/graphql/queries'
import { CalendarSelection as CalendarSelectionOld, Maybe } from '@bidfood/graphql/types'
import { CalendarSelection } from '../types'

export const fetchSavedCalendar: CalendarActions[CalendarActionNames.FETCH_SAVED_CALENDAR] =
  async function fetchSavedCalendar() {
    const { data } = await apolloHeaderClient.query({
      query: CALENDAR_SELECTION_QUERY,
      fetchPolicy: 'network-only'
    })
    if (data.calendarSelection) {
      this.calendarSelection = calendarSelectionMapper(data.calendarSelection)
    }
  }

//  TODO - Remove this after changes in BFF  on 3rd March 2023
const calendarSelectionMapper = (calendarSelectionOld: CalendarSelectionOld) =>
  ({
    isCalendarOptionsSet: calendarSelectionOld.is_calendar_options_set as Maybe<boolean>,
    orderReference: calendarSelectionOld.order_reference as Maybe<string>,
    rememberFirstPossibleDeliveryDate: calendarSelectionOld.remember_first_possible_delivery_date as Maybe<boolean>,
    selectedDeliveryDate: calendarSelectionOld.selected_delivery_date as Maybe<string>,
    selectedFulfillmentType: calendarSelectionOld.selected_fulfillment_type as Maybe<string>,
    selectedOrderType: calendarSelectionOld.selected_order_type as Maybe<string>,
    selectedPickupEndTime: calendarSelectionOld.selected_pickup_end_time as Maybe<string>,
    selectedPickupStartTime: calendarSelectionOld.selected_pickup_start_time as Maybe<string>,
    selectedTc: calendarSelectionOld.selected_tc as Maybe<string>,
    selectedTimeFrame: calendarSelectionOld.selected_time_frame as Maybe<string>
  } satisfies CalendarSelection)
