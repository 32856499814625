import { OrderActionNames, OrderActions } from './types'
import { DeliveryExtended, TimeWindow } from '../types'
import { apolloPortalClient } from '@bidfood/clients'
import { parse } from 'date-fns'
import { Delivery, Order } from '@bidfood/graphql/types'
import { GET_DELIVERIES } from '@bidfood/graphql/queries'
import { formatDate, formatPriceToEuro, processOrders } from '@bidfood/utils'
import { OrderType, ShippingType, CustomEvent, CustomerServiceJourneys } from '@bidfood/types'
import { useUiStore } from '@bidfood/store'
import { trigger } from '@bidfood/global-event-bus'

export const fetchDeliveries: OrderActions[OrderActionNames.FETCH_DELIVERIES] = async function (
  orderType: OrderType,
  dateFrom?: string,
  dateTill?: string
) {
  this[orderType] = []
  const uiStore = useUiStore()
  uiStore.loadingStates.isDeliveryLoading = true
  const sortDirection: Direction = orderType === OrderType.UPCOMING ? 'ASC' : 'DESC'
  try {
    const { data } = await apolloPortalClient.query({
      query: GET_DELIVERIES,
      variables: {
        ...(orderType !== OrderType.UPCOMING && {
          dateFrom,
          dateTill
        }),
        sortDirection
      }
    })
    if (data.deliveries) {
      this[orderType] = processDeliveries(data.deliveries as Delivery[])
    }
    uiStore.loadingStates.isDeliveryLoading = false
  } catch (e) {
    trigger(CustomEvent.SHOW_ERROR_MODAL, {
      customerServiceJourney: CustomerServiceJourneys.ORDERS_TECHNICAL_ERROR
    })
  }
}
export type Direction = 'ASC' | 'DESC'

const calculateTimeWindow = (delivery: Delivery): TimeWindow => {
  switch (delivery.shippingType) {
    case 'Bezorgen':
      return {
        from: delivery.deliveryStatus?.deliveryMoment?.from || null,
        till: delivery.deliveryStatus?.deliveryMoment?.till || null
      }
    case 'Afhalen':
      return {
        from: delivery.pickupInformation?.pickupTimeFrom || null,
        till: delivery.pickupInformation?.pickupTimeTill || null
      }
    default:
      return {
        from: null,
        till: null
      }
  }
}

const calculateProductsNotDelivered = (orders: Order[]) => {
  return orders.reduce((acc, order) => acc + (order.numberOfProductsNotDelivered || 0), 0)
}

function processDeliveries(deliveries: Delivery[]): DeliveryExtended[] {
  const currentDate = new Date().toISOString().split('T')[0]
  return deliveries.map((delivery) => {
    const isCurrentDayDelivery = delivery.deliveryDate === currentDate
    const isDeliveryTimeUpdated = delivery.deliveryStatus?.deliveryMoment?.isDeliveryTimeUpdated
    return {
      ...delivery,
      deliveryDateFormatted: formatDate(delivery.deliveryDate, 'd MMMM yyyy'),
      deliveryStatus: delivery.deliveryStatus?.status?.name || '',
      isDeliveryTimeUpdated,
      deliveryCost: delivery.deliveryCost ? formatPriceToEuro(delivery.deliveryCost) : '',
      isCurrentDayDelivery,
      timeWindow: calculateTimeWindow(delivery),
      productsNotDelivered: calculateProductsNotDelivered(delivery.orders),
      shippingType: delivery.shippingType as ShippingType,
      noOfOrders: delivery.orders.length,
      orders: processOrders(delivery.orders, isCurrentDayDelivery)
    }
  }) satisfies DeliveryExtended[]
}

const deliveriesSortOrder = [
  ShippingType.DELIVER,
  ShippingType.DELIVERED,
  ShippingType.PICK_UP,
  ShippingType.PICKED_UP,
  ShippingType.DELIVER_BY_SUPPLIER,
  ShippingType.DELIVERED_BY_SUPPLIER
]

function sortDeliveries(deliveryDetails: Delivery[], dateDirection: Direction = 'ASC'): Delivery[] {
  return deliveryDetails.slice().sort((a, b) => {
    const dateA = parse(a.deliveryDate, 'yyyy-MM-dd', new Date())
    const dateB = parse(b.deliveryDate, 'yyyy-MM-dd', new Date())
    const firstLevelSort =
      dateDirection === 'ASC' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime()
    const secondLevelSort =
      deliveriesSortOrder.indexOf(a.shippingType as ShippingType) -
      deliveriesSortOrder.indexOf(b.shippingType as ShippingType)
    return firstLevelSort || secondLevelSort
  })
}
