import { graphql } from '@bidfood/graphql/generated'

export const GET_CUSTOMER_SERVICE_DETAILS = graphql(/* GraphQL */ `
  query customerServiceDetails {
    currentCustomer {
      serviceCenter {
        city
        email
        phoneNumber
        showChat
        liveChatChannel
      }
    }
  }
`)
