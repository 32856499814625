import { UserActionNames, UserActions } from './types'
import { GET_LOCATIONS_QUERY } from '@bidfood/graphql/queries'
import { apolloPortalClient } from '@bidfood/clients'
import { Location } from '@bidfood/graphql/types'
import { useUiStore } from '@bidfood/store'
import { LocationExtended } from '../types'

export const fetchUserLocations: UserActions[UserActionNames.FETCH_USER_LOCATIONS] = async function () {
  const uiStore = useUiStore()
  uiStore.loadingStates.isLocationLoading = true
  const { data } = await apolloPortalClient.query({
    query: GET_LOCATIONS_QUERY
  })
  this.locations = data.getLocation?.map((location: Location) => {
    return {
      ...location,
      locationDetail: `${location.name} (${location.id})`
    }
  }) satisfies LocationExtended[]
  uiStore.loadingStates.isLocationLoading = false
}
