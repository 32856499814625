import { graphql } from '@bidfood/graphql/generated'

export const UPDATE_USER_PROFILE_BY_USER_ID = graphql(/* GraphQL */ `
  mutation updateUserProfileByUserId($userProfileInput: UserProfileInput) {
    updateUserProfileByUserId(userProfileInput: $userProfileInput) {
      firstName
      middleName
      lastName
      email
      secondaryEmail
      phoneNumber
      username
      role
      isPrimaryEmailEnabled
      isPhoneNumberAvailable
    }
  }
`)
