import { CalendarGetterNames, CalendarGetters } from './types'
import { CalendarState } from '../types'
import { CalenderPreference, DeliveryDateInfo } from '@bidfood/graphql/types'
import { FulfillmentType } from '@bidfood/types'
import { format, parse } from 'date-fns'
import { nl } from 'date-fns/locale'

const pickupTcs: CalendarGetters[CalendarGetterNames.PICKUP_TCS] = function ({ calendar }) {
  return calendar.pickupTcs
}

const deliveryDatesCollection: CalendarGetters[CalendarGetterNames.DELIVERY_DATES_COLLECTION] = function ({
  calendar
}) {
  return calendar.deliveryDates.reduce((mapAccumulator, deliveryDateInfo) => {
    mapAccumulator.set(deliveryDateInfo.deliveryDate, deliveryDateInfo)
    return mapAccumulator
  }, new Map<string, DeliveryDateInfo>())
}

const lastSavedCalendarSelection: CalendarGetters[CalendarGetterNames.LAST_SAVED_CALENDAR_SELECTION] = function ({
  calendarSelection
}) {
  return calendarSelection
}
const canShowPickUpOption: CalendarGetters[CalendarGetterNames.CAN_SHOW_PICKUP_OPTION] = function ({
  calenderPreferences,
  calendar
}) {
  return calenderPreferences.includes(CalenderPreference.PickupOption) && calendar.pickupTcs.length > 0
}

const hasMultiplePickupTcs: CalendarGetters[CalendarGetterNames.HAS_MULTIPLE_PICKUP_TCS] = function ({ calendar }) {
  return calendar.pickupTcs.length > 1
}

const canShowLeadTime: CalendarGetters[CalendarGetterNames.CAN_SHOW_LEAD_TIME] = function ({ calenderPreferences }) {
  return calenderPreferences.includes(CalenderPreference.LeadTimeOrder)
}

const getInfoMessage: CalendarGetters[CalendarGetterNames.GET_INFO_MESSAGE] = function ({ calendar }) {
  return calendar.deliveryInfoMessage || ''
}

const deliveryDateInfoText: CalendarGetters[CalendarGetterNames.DELIVERY_DATE_INFO_TEXT] = function (
  state: CalendarState
) {
  const DEFAULT_DELIVERY_INFO_TEXT = 'Kies datum'
  const NEXT_POSSIBLE_DELIVERY_INFO_TEXT = 'Eerstvolgende'
  if (!state.calendarSelection) {
    return DEFAULT_DELIVERY_INFO_TEXT
  }

  const { selectedFulfillmentType, selectedDeliveryDate, rememberFirstPossibleDeliveryDate } = state.calendarSelection

  if (rememberFirstPossibleDeliveryDate) {
    return NEXT_POSSIBLE_DELIVERY_INFO_TEXT
  }
  switch (selectedFulfillmentType) {
    case FulfillmentType.DELIVER_BY_NEXT_DAY:
      return NEXT_POSSIBLE_DELIVERY_INFO_TEXT
    case FulfillmentType.DELIVERY:
    case FulfillmentType.PICK_UP: {
      if (!selectedDeliveryDate) {
        return DEFAULT_DELIVERY_INFO_TEXT
      }
      const parsedDate = parse(selectedDeliveryDate, 'yyyyMMdd', new Date())
      return format(parsedDate, 'EEEE d LLL yyyy', { locale: nl }).replace('.', '')
    }
    default:
      return DEFAULT_DELIVERY_INFO_TEXT
  }
}

export const getters: CalendarGetters = {
  lastSavedCalendarSelection,
  canShowPickUpOption,
  hasMultiplePickupTcs,
  canShowLeadTime,
  deliveryDateInfoText,
  pickupTcs,
  deliveryDatesCollection,
  getInfoMessage
}
