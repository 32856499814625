import { CalendarState } from './types'

export const calendarState: CalendarState = {
  calendar: {
    deliveryDates: [],
    pickupTcs: [],
    deliveryInfoMessage: ''
  },
  calendarSelection: null,
  calenderPreferences: []
}
