import { graphql } from "@bidfood/graphql/generated";

export const GET_LOCATIONS_QUERY = graphql(/* GraphQL */ `
  query getLocations {
    getLocation {
      id
      name
      users {
        lastName
        middleName
        firstName
        email
      }
    }
  }
`);
