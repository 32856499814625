import { OrderGetterNames, OrderGetters } from './types'
import { OrderState } from '../types'

const pickingCorrectionDetailsDelivery: OrderGetters[OrderGetterNames.PICKING_CORRECTION_DETAILS_DELIVERY] = function (
  state: OrderState
) {
  return (): any =>
    (state.currentPickingCorrectionSelection && state.pickingCorrections[state.currentPickingCorrectionSelection]) || {}
}

export const getters: OrderGetters = {
  pickingCorrectionDetailsDelivery
}
