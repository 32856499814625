import { UserActionNames, UserActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_CUSTOMER_LOCATION_DETAILS_QUERY } from '@bidfood/graphql/queries'
import { useUiStore } from '@bidfood/store'

export const fetchCustomerServiceLocationDetails: UserActions[UserActionNames.FETCH_CUSTOMER_SERVICE_LOCATION_DETAILS] =
  async function (locationId: string) {
    const uiStore = useUiStore()
    uiStore.loadingStates.isLocationDetailsLoading = true
    const { data } = await apolloPortalClient.query({
      query: GET_CUSTOMER_LOCATION_DETAILS_QUERY,
      variables: { customerNumber: locationId }
    })
    this.customerDetails[locationId] = data.customer
    uiStore.loadingStates.isLocationDetailsLoading = false
  }
