import { graphql } from '@bidfood/graphql/generated'

export const UPDATE_CALENDAR_MUTATION = graphql(/* GraphQL */ `
    mutation updateCalendar(
        $rememberFirstPossibleDeliveryDate: Boolean
        $userSelectedDeliveryDate: String
        $orderReference: String
        $siteId: String
        $fulfillmentType: String
        $selectedTc: String
        $pickupStartTime: String
        $pickupEndTime: String
        $selectedTimeFrame: String
        $orderType: String
    ) {
        updateCalendar(
            rememberFirstPossibleDeliveryDate: $rememberFirstPossibleDeliveryDate
            userSelectedDeliveryDate: $userSelectedDeliveryDate
            orderReference: $orderReference
            siteId: $siteId
            fulfillmentType: $fulfillmentType
            selectedTC: $selectedTc
            pickupStartTime: $pickupStartTime
            pickupEndTime: $pickupEndTime
            selectedTimeFrame: $selectedTimeFrame
            orderType: $orderType
        )
    }
`)
