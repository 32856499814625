export { formatPriceToEuro, formatDate, readRegExpArrayFromConfigString, getMonthYear, capitalize } from './format'
export { processOrders } from './order'
export { sortInvoices, sortInvoicesOrder } from './invoice'
export { calculateQuickFilterDateRange } from './date'
export { clearLocalStorage, clearSessionStorage } from './cache/clearLocalStorage'

export function isNumeric(str: string) {
    if (typeof str != "string") return false
    return !isNaN(parseFloat(str))
  }
