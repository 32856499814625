import { onError } from '@apollo/client/link/error'
import { PageUrl } from '@bidfood/types'

export enum BFF_URI_LOCAL {
  HEADER="http://localhost:6600/graphql",
  PORTAL="http://localhost:6700/graphql",
}

export enum BFF_URI {
  HEADER = '/bff/header/graphql',
  PORTAL = '/bff/portal/graphql',
}


export const errorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ path, extensions }) => {
      const listOfQueryNameToCheck = ['getLocation', 'getUserProfile']
      const isValidQueryPath = path && typeof path[0] === 'string' && listOfQueryNameToCheck.includes(path[0])
      if (extensions && extensions.code === 'UNAUTHENTICATED' && isValidQueryPath) {
        setTimeout(() => {
          window.location.href = PageUrl.LOGIN_URL
        }, 50)
      }
    })
  }

})

export function isLoginPage() {
  const loginPagePaths: string[] = [PageUrl.LOGIN_URL, PageUrl.OCI_LOGIN_URL]
  return loginPagePaths.includes(window.location.pathname)
}
