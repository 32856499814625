import { UiState } from './types'
import { SlideOverType } from '@bidfood/types'

export const uiState: UiState = {
  isLocationSelectOpen: false,
  isCustomerServiceModalOpen: false,
  isDownloadInvoiceErrorModalOpen: false,
  loadingStates: {
    isSearchLoading: false,
    isLocationSelectLoading: false,
    isPickingCorrectionLoading: false,
    isDeliveryLoading: false,
    isInvoiceLoading: false,
    isOnHoldOrdersLoading: false,
    isContactPreferenceLoading: false,
    isUserLoading: false,
    isLocationLoading: false,
    isLocationDetailsLoading: false,
    isInsightsLoading: false,
    invoiceDownloadLoadingCollection: {}
  },
  isLocationSelectClosable: true,
  locationSelectCallback: () => null,
  isCalendarModalOpen: false,
  isCategorySlideOverOpen: false,
  isOfferSliderOverOpen: false,
  isShopSlideOverOpen: false,
  isAllPurchaseListSlideOverOpen: false,
  slideOvers: {
    [SlideOverType.ALL_PURCHASE_LIST]: { isOpen: false },
    [SlideOverType.CREATE_PURCHASE_LIST]: { isOpen: false }
  }
}
