import { defineStore } from 'pinia'
import { getters } from './getters'
import { actions } from './actions'
import { userState } from './state'

const userStoreId = 'user'

export const userStore = defineStore(userStoreId, {
  state: () => userState,
  getters,
  actions
})
