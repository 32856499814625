import { ContactPreferenceActionNames, ContactPreferenceActions } from './types'
import { DocumentKey } from '../types'

export const updateContactPreference: ContactPreferenceActions[ContactPreferenceActionNames.UPDATE_CONTACT_PREFERENCE] =
  function (document: DocumentKey, email: string, emailOld: string) {
    const index = this.preferenceBasedOnDocument[document].contactPreferences.findIndex(
      (preference) => preference.email === emailOld
    )
    if (index >= 0) {
      this.preferenceBasedOnDocument[document] = {
        ...this.preferenceBasedOnDocument[document],
        contactPreferences: [
          ...this.preferenceBasedOnDocument[document].contactPreferences.slice(0, index),
          { email },
          ...this.preferenceBasedOnDocument[document].contactPreferences.slice(index + 1)
        ]
      }
    }
  }
