import { UserActionNames, UserActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { GET_LOCATIONS_QUERY } from '@bidfood/graphql/queries'



export const fetchLocationsLogin: UserActions[UserActionNames.FETCH_LOCATIONS_LOGIN] = async function () {
  const { data } = await apolloPortalClient.query({
    query: GET_LOCATIONS_QUERY
  })
  this.selectedLocation = {
    id: '',
    name: ''
  }
  this.availableLocations = data.getLocation
}
