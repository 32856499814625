import { ContactPreferenceActionNames, ContactPreferenceActions } from './types'
import { apolloPortalClient } from '@bidfood/clients'
import { UPDATE_CONTACT_PREFERENCE } from '@bidfood/graphql/mutations'
import { triggerContactPreferenceEvent } from '@bidfood/utils/analytics'
import { trigger } from '@bidfood/global-event-bus'
import { CustomerServiceJourneys, CustomEvent } from '@bidfood/types'

export const mutateContactPreference: ContactPreferenceActions[ContactPreferenceActionNames.MUTATE_CONTACT_PREFERENCE] =
  async function (input: { document: string; emailNew: string; emailOld?: string }) {
    let isSuccess = false
    try {
      await apolloPortalClient.mutate({
        mutation: UPDATE_CONTACT_PREFERENCE,
        variables: { input },
        fetchPolicy: 'no-cache'
      })
      isSuccess = true
    } catch (e) {
      isSuccess = false
      trigger(CustomEvent.SHOW_ERROR_MODAL, {
        customerServiceJourney: CustomerServiceJourneys.CP_TECHNICAL_ERROR
      })
    } finally {
      triggerContactPreferenceEvent(input, isSuccess)
    }
    return { isSuccess }
  }
