import { graphql } from '@bidfood/graphql/generated'

export const SEARCH_BOX_CONFIGURATION_QUERY = graphql(/* GraphQL */ `
query searchBoxConfiguration {
    searchBoxConfiguration {
        suggestionHeading
        minAutoSuggestInputLength
        defaultSuggestions {
            name
            url
        }
        banner {
            dataId
            title
            linkText
            url
            imageURL
        }
    }
}

`)