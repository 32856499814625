import { ContactPreferenceActionNames, ContactPreferenceActions } from './types'
import { DocumentKey } from '../types'

export const addNewContactPreference: ContactPreferenceActions[ContactPreferenceActionNames.ADD_NEW_CONTACT_PREFERENCE] =
  function (document: DocumentKey, email: string) {
    this.preferenceBasedOnDocument[document] = {
      ...this.preferenceBasedOnDocument[document],
      contactPreferences: [...this.preferenceBasedOnDocument[document].contactPreferences, { email }]
    }
  }
