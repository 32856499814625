export { CustomEvent } from './custom-event'
export { LocalStorageItem } from './local-storage-item'
export { BrowserStorageItem } from './browser-storage-item'
export { PageUrl } from './page-url'
export { SlideOverType } from './slide-over-type'
export { CustomerServiceJourneys } from './customer-service-journeys'
export enum QuickFilter {
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  LAST_30_DAYS = 'Last 30 Days'
}

export enum ShippingType {
  DELIVER = 'Bezorgen',
  DELIVERED = 'Bezorgd',
  PICK_UP = 'Afhalen',
  PICKED_UP = 'Afgehaald',
  DELIVER_BY_SUPPLIER = 'Bezorgen door leverancier',
  DELIVERED_BY_SUPPLIER = 'Bezorgd door leverancier'
}

export enum OrderType {
  UPCOMING = 'upcoming',
  ON_HOLD = 'onHold',
  HISTORY = 'history'
}

export enum FulfillmentType {
  DELIVERY = 'B',
  PICK_UP = 'A',
  DELIVER_BY_NEXT_DAY = 'F'
}

export enum PickupTimeFrame {
  MORNING = 'Ochtend',
  AFTERNOON = 'Middag'
}

export enum LeadTime {
  NORMAL_LEAD_TIME = 'NO',
  SHORT_LEAD_TIME = 'MK'
}
