import { graphql } from '@bidfood/graphql/generated'

export const GET_CONTACT_PREFERENCES_QUERY = graphql(/* GraphQL */ `
    query getContactPreferences($document: String!) {
    getContactPreferencesByCustomerNumber(document: $document) {
        contactPreferenceProcessSetup
        contactPreferences {
            email
        }
    }
    }
`)