import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core'
import { BFF_URI, BFF_URI_LOCAL, errorHandler, isLoginPage } from './config'
import { AsyncStorageWrapper, persistCache } from 'apollo3-cache-persist'
import { BrowserStorageItem } from '@bidfood/types'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.NODE_ENV === 'development' ? BFF_URI_LOCAL.HEADER : BFF_URI.HEADER,
  fetchOptions: {
    mode: 'cors'
  }
})

// Cache implementation
const inMemoryCache = new InMemoryCache({ addTypename: false })

// Create the apollo client
const apolloHeaderClient = new ApolloClient({
  link: errorHandler.concat(httpLink),
  cache: inMemoryCache
})

export default apolloHeaderClient

let apolloHeaderPersistentClient : ApolloClient<NormalizedCacheObject> | null = null

export const createApolloHeaderClient = async function(key = BrowserStorageItem.HEADER_GRAPHQL_QUERY_PERSISTENCE_KEY) {

  if(isLoginPage()) {
    return apolloHeaderClient
  }

  if (apolloHeaderPersistentClient) {
    return  Promise.resolve(apolloHeaderPersistentClient)
  }
  const CUSTOMER_AGENT_IDENTIFIER = 'csc'
  const isCustomerAgent = window.location.hostname.includes(CUSTOMER_AGENT_IDENTIFIER)
  await persistCache({
    cache: inMemoryCache,
    storage: new AsyncStorageWrapper((isCustomerAgent ? window.sessionStorage: window.localStorage) as any),
    key
  })

  apolloHeaderPersistentClient = new ApolloClient({
    link: errorHandler.concat(httpLink),
    cache: inMemoryCache,
  })

  return apolloHeaderPersistentClient
}
